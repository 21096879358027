// @ts-ignore
import $ from 'jquery';
import owlCarousel from 'owl.carousel';
import WOW  from 'wowjs'
new WOW.WOW().init();
import magnificPopup from 'magnific-popup';
!(function ($)  {
	"use strict";

	var windowOn = $(window);


	////////////////////////////////////////////////////
	// 04. Body overlay Js
	$(".body-overlay").on("click", function () {
		$(".sidebar__area").removeClass("sidebar-opened");
		$(".body-overlay").removeClass("opened");
	});

	////////////////////////////////////////////////////
	// 05. Search Js
	$(".search-toggle").on("click", function () {
		$(".search__area").addClass("opened");
	});
	$(".search-close-btn").on("click", function () {
		$(".search__area").removeClass("opened");
	});

	////////////////////////////////////////////////////
	// 06. Sticky Header Js
	windowOn.on('scroll', function () {
		var scroll = $(window).scrollTop();
		if (scroll < 100) {
			$("#header-sticky").removeClass("header__sticky");
		} else {
			$("#header-sticky").addClass("header__sticky");
		}
	});

	////////////////////////////////////////////////////
	// 07. Data CSS Js
	$("[data-background").each(function () {
		$(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
	});

	$("[data-width]").each(function () {
		$(this).css("width", $(this).attr("data-width"));
	});

	$("[data-bg-color]").each(function () {
		$(this).css("background-color", $(this).attr("data-bg-color"));
	});



	////////////////////////////////////////////////////
	// 23. InHover Active Js
	$('.hover__active').on('mouseenter', function () {
		$(this).addClass('active').parent().siblings().find('.hover__active').removeClass('active');
	});


	// Jquery Appear raidal
	if (typeof ($.fn.knob) != 'undefined') {
		$('.knob').each(function () {
			var $this = $(this),
				knobVal = $this.attr('data-rel');

			$this.knob({
				'draw': function () {
					$(this.i).val(this.cv + '%')
				}
			});

			$this.appear(function () {
				$({
					value: 0
				}).animate({
					value: knobVal
				}, {
					duration: 2000,
					easing: 'swing',
					step: function () {
						$this.val(Math.ceil(this.value)).trigger('change');
					}
				});
			}, {
				accX: 0,
				accY: -150
			});
		});
	}



	// colse button
	if (jQuery(".close-button").length > 0) {
		const closeBtn = document.querySelector('.close-button');
		closeBtn.addEventListener('click', function () {
			const append = document.querySelector('.appoinment-cta-2');
			append.classList.add('hide')
		})
	}

})($, window, jQuery);

!function(s) {
    "use strict";
	s(".switch").on("click",
		function() {
			s("body").hasClass("light") ? (s("body").removeClass("light"), s(".switch").removeClass("switched")) : (s("body").addClass("light"), s(".switch").addClass("switched"))
	}),
    s(document).ready(function() {
		s('.image-link').magnificPopup({removalDelay: 300,mainClass: 'mfp-fade'});
		jQuery(document).on("mouseenter", ".suspension .a", function(){
			var _this = jQuery(this);
			var s = jQuery(".suspension");
			var isService = _this.hasClass("a-service");
			var isServicePhone = _this.hasClass("a-service-phone");
			var isQrcode = _this.hasClass("a-qrcode");
			if(isService){ s.find(".d-service").show().siblings(".d").hide();}
			if(isServicePhone){ s.find(".d-service-phone").show().siblings(".d").hide();}
			if(isQrcode){ s.find(".d-qrcode").show().siblings(".d").hide();}
		});
		jQuery(document).on("mouseleave", ".suspension", function(){
			jQuery(".suspension").find(".d").hide();
		});
		jQuery(document).on("mouseenter", function(){
			jQuery(".suspension").find(".d").hide(); 
		});
		/* ------------------  MODULE SEARCH  ------------------ */

		var $moduleSearch = s(".module-icon-search"),
        $searchWarp = s(".module-search-warp");

		$moduleSearch.on("click", function() {
			s(this).parent().addClass("module-active");
			s(this).parent().siblings().removeClass("module-active");
			$searchWarp.addClass("search-warp-active");
		});
		/* ------------------  MODULE CANCEL ------------------ */

		var $module = $(".module"),
        $moduleWarp = $(".module-warp"),
        $moduleCancel = $(".module-cancel");

		$moduleCancel.on("click", function(e) {
			$module.removeClass("module-active");
			$searchWarp.removeClass("search-warp-active");
			e.stopPropagation();
			e.preventDefault();
		});

		$(document).keyup(function(e) {
			if (e.key === "Escape") {
				$module.removeClass("module-active");
				$moduleWarp.removeClass("active");
				$searchWarp.removeClass("search-warp-active");
				$popMenuWarp.removeClass("popup-menu-warp-active");
			}
		});
		/* ------------------  MOBILE MENU ------------------ */

		var $w = s(window);
		var $wWidth = $w.width();
		var mobile_resolution_size = "1200";
		var $dropToggle = s("[data-toggle='dropdown']");
		$dropToggle.on("click", function(event) {
			s(this).each(() => {
				if ($wWidth <= mobile_resolution_size && s$(this).attr('href') === '#') {
					event.preventDefault();
					event.stopPropagation();
					s(this).parent().siblings().removeClass("show");
					s(this).parent().toggleClass("show");
				} else if ($wWidth <= mobile_resolution_size && !s(this).attr('href') !== '#') {
					event.preventDefault();
					event.stopPropagation();
					s(this).parent().siblings().removeClass("show");
					s(this).parent().toggleClass("show");
					s(this).children('span').on('click', () => {
						window.location.href = s(this).attr('href');
					})
				}
			})
		});
		/* ------------------ NAVBAR STICKY ------------------ */

		s(window).scroll(function() {
			if (s(document).scrollTop() > 100) {
				s('.navbar-sticky').addClass('navbar-fixed');
			} else {
				s('.navbar-sticky').removeClass('navbar-fixed');
			}
		});
		/* ------------------  BACK TO TOP ------------------ */

		var backTop = $('#back-to-top');

		if (backTop.length) {
			var scrollTrigger = 600, // px
				backToTop = function() {
					var scrollTop = $(window).scrollTop();
					if (scrollTop > scrollTrigger) {
						backTop.addClass('show');
					} else {
						backTop.removeClass('show');
					}
				};
			backToTop();
			$(window).on('scroll', function() {
				backToTop();
			});
			backTop.on('click', function(e) {
				e.preventDefault();
				$('html,body').animate({
					scrollTop: 0
				}, 700);
			});
		};

		/* ------------------  Background INSERT ------------------ */

		var $bgSection = $(".bg-section");
		var $bgPattern = $(".bg-pattern");
		var $colBg = $(".col-bg");
	
		$bgSection.each(function() {
			var bgSrc = $(this).children("img").attr("src");
			var bgUrl = 'url(' + bgSrc + ')';
			$(this).parent().css("backgroundImage", bgUrl);
			$(this).parent().addClass("bg-section");
			$(this).remove();
		});
	
		$bgPattern.each(function() {
			var bgSrc = $(this).children("img").attr("src");
			var bgUrl = 'url(' + bgSrc + ')';
			$(this).parent().css("backgroundImage", bgUrl);
			$(this).parent().addClass("bg-pattern");
			$(this).remove();
		});
	
		$colBg.each(function() {
			var bgSrc = $(this).children("img").attr("src");
			var bgUrl = 'url(' + bgSrc + ')';
			$(this).parent().css("backgroundImage", bgUrl);
			$(this).parent().addClass("col-bg");
			$(this).remove();
		});
		/* ------------------ OWL CAROUSEL ------------------ */

		var $carouselDirection = $("html").attr("dir");
		if ($carouselDirection == "rtl") {
			var $carouselrtl = true;
		} else {
			var $carouselrtl = false;
		}
	
		$(".carousel").each(function() {
			var $Carousel = $(this);
			$Carousel.owlCarousel({
				loop: $Carousel.data('loop'),
				autoplay: $Carousel.data("autoplay"),
				margin: $Carousel.data('space'),
				nav: $Carousel.data('nav'),
				dots: $Carousel.data('dots'),
				dotsSpeed: $Carousel.data('speed'),
				mouseDrag: $Carousel.data('drag'),
				touchDrag: $Carousel.data('drag'),
				pullDrag: $Carousel.data('drag'),
				rtl: $carouselrtl,
				responsive: {
					0: {
						items: 1,
					},
					768: {
						items: $Carousel.data('slide-rs'),
					},
					1000: {
						items: $Carousel.data('slide'),
						center: $Carousel.data('center'),
					}
				}
			});
		});
	
		$(".slider-carousel").each(function() {
			var $Carousel = $(this);
			$Carousel.owlCarousel({
				loop: $Carousel.data('loop'),
				autoplay: $Carousel.data("autoplay"),
				margin: $Carousel.data('space'),
				nav: $Carousel.data('nav'),
				dots: $Carousel.data('dots'),
				center: $Carousel.data('center'),
				dotsSpeed: $Carousel.data('speed'),
				rtl: $carouselrtl,
				responsive: {
					0: {
						items: 1,
					},
					768: {
						items: $Carousel.data('slide-rs'),
					},
					1000: {
						items: $Carousel.data('slide'),
					}
				},
				animateOut: 'fadeOut',
				//animateOut: 'fadeOutLeft',
				//animateIn: 'flipInX',
				//smartSpeed: 450
			});
		});

		/* ------------------ MAGNIFIC POPUP ------------------ */

		var $imgPopup = $(".img-popup");
		$imgPopup.magnificPopup({
			type: "image"
		});
		$('.img-gallery-item').magnificPopup({
			type: 'image',
			gallery: {
				enabled: true
			}
		});
	
		/* ------------------  MAGNIFIC POPUP VIDEO ------------------ */
	
		$('.popup-video,.popup-gmaps').magnificPopup({
			disableOn: 700,
			mainClass: 'mfp-fade',
			removalDelay: 0,
			preloader: false,
			fixedContentPos: false,
			type: 'iframe',
			iframe: {
				markup: '<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
					'</div>',
				patterns: {
					youtube: {
						index: 'youtube.com/',
						id: 'v=',
						src: '//www.youtube.com/embed/%id%?autoplay=1'
					}
				},
				srcAction: 'iframe_src',
			}
		});
	
		// Clicking On Thumbs
		$('.testimonial-thumbs .testimonial-thumb').on('click', function() {
			$(this).siblings(".testimonial-thumb").removeClass('active');
			$(this).addClass('active');
			$(".testimonials-carousel").trigger('to.owl.carousel', [$(this).index(), 300]);
		});
		// Draging The Carousel And The Thumbs Still has Active Class 
		$(".testimonials-carousel").on('changed.owl.carousel', function(event) {
			var items = event.item.count; // Number of items
			var item = event.item.index; // Position of the current item
			var owlDots = document.querySelectorAll('.testimonial-thumbs .testimonial-thumb');
			if (owlDots.length > 0) {
				owlDots[item].click()
			}
		});
	
		// Draging The Carousel And Link It To Another Carousel
		$(".process-content-carousel").on('changed.owl.carousel', function(event) {
			var items = event.item.count; // Number of items
			var item = event.item.index; // Position of the current item
			$(".process-image-carousel").trigger('to.owl.carousel', [item, 800]);
		})
		// Draging The Carousel And Link It To Another Carousel [ On Page Service Single ]
		$('.entry-processes .images-holder .process-image-carousel').on('changed.owl.carousel', function(event) {
			var items = event.item.count; // Number of items
			var item = event.item.index; // Position of the current item
			$(".entry-processes .entry-body .process-content-carousel").trigger('to.owl.carousel', [item, 800]);
		})
    })
} ($);