<template>
    <swiper
        ref="mySwiper"
        :options="swiperOptions"
        class="foobar"
    >
        <slot
            v-for="(_, name) in $slots"
            :slot="name"
            :name="name"
        />
    </swiper>
</template>
<script>
import { Swiper, directive } from 'vue-awesome-swiper'

const variants = {
    default: {
        spaceBetween: 40,
        centeredSlides: true,
        slidesPerView: 2.25,
        initialSlide: 1,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
        },
    },
    bleedRight: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop:true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },   
    },
    dining: {
        slidesPerView: 1,
        spaceBetween: 40,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }, 

        breakpoints: {
            616: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1000: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        },
    },
    history: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
            el: '.steps-slider__nav',
        },
        breakpoints: {
            616: {
                slidesPerView: 2,
                spaceBetween: 0,
            },
            1000: {
                slidesPerView: 3,
                spaceBetween: 0,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 0,
            },
        },
    },
    relatedcarousel: {
        slidesPerView: 1,
        spaceBetween: 40,
        navigation: {
            nextEl: '.services-button-prev',
            prevEl: '.services-button-prev',
        }, 

        breakpoints: {
            616: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1000: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        },
    },
}

export default {
    components: {
        Swiper,
    },
    directives: {
        swiper: directive,
    },
    props: {
        variant: {
            type: String,
            default: 'default',
        },
        swiperOptions: {
            type: Object,
            default () {
                return {
                    keyboard: {
                        enabled: true,
                    },
                    navigation: {
                        nextEl: '.carousel__room-highlights-card__carousel-controls-next',
                        prevEl: '.carousel__room-highlights-card__carousel-controls-prev',
                    },
                    ...variants[this.variant],
                }
            },
        },
    },
}
</script>
