import { render, staticRenderFns } from "./BpDropdown.vue?vue&type=template&id=45e7ee3b&"
import script from "./BpDropdown.vue?vue&type=script&lang=js&"
export * from "./BpDropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports